import * as React from 'react';
import { useState } from 'react';
import { Avatar, ButtonGroup, Card, CssBaseline, Divider, Grid, Paper, ThemeProvider } from "@mui/material"
import {
    Outlet
} from "react-router-dom";
import { Container, Stack } from '@mui/system';
import { Canvas } from "@react-three/fiber"

//Component import
import Typography from "../../components/Typography"
import Navbar from '../../components/Navbar';
import { LinkedInButton, NPMButton, GithubButton, DiscordProfile, RobloxProfile } from '../../components/MediaButtons';
import GalaxyBackground from '../GalaxyBackground';
import ProfileCard from '../ProfileCard';

//Theme import
import theme from '../../modules/theme';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function MainLayout(props) {

    return (
        <ThemeProvider theme={theme}>
            <Grid container>
                <GalaxyBackground />
                <Container>
                    <Container sx={{ marginBottom: 3 }}></Container>
                    <ProfileCard />
                    <Container maxWidth="xl" disableGutters>
                        <Outlet />
                    </Container>
                </Container>
            </Grid>
            <CssBaseline />
        </ThemeProvider>


    );
}

export default MainLayout;
