import { Box, Button, Icon, IconButton, Typography } from "@mui/material";
import styled from "@emotion/styled";
import {
    useHover,
    useFloating,
    useInteractions,
    FloatingArrow,
    arrow,
    offset,
    useTransitionStyles,
    autoPlacement,
    detectOverflow
} from "@floating-ui/react";
import React, { useRef, useState } from "react";

//Icon import
import { LinkedIn, GitHub } from "@mui/icons-material"

//Component import

var MediaButtonBase = (props) => {
    //-------------------------
    //Render media button
    return <React.Fragment>
        <Button target="_blank"
            size="small"
            sx={{ textTransform: "none"}}
            {...props} />

    </React.Fragment>
}

var LinkedInButton = () => {
    return (
        <MediaButtonBase alt="LinkedIn Profile" href="https://www.linkedin.com/in/scottricity/">
            <img width={80} alt="LinkedIn" src="../brands/LinkedInBlue.png" />
        </MediaButtonBase>
    )
}

var NPMButton = () => {
    return (
        <MediaButtonBase alt="NPM Profile" href="https://www.npmjs.com/~scottricity" color="error">
            <img width={50} alt="NPM" src="../brands/npmLogo.png" />
        </MediaButtonBase>
    )
}

var GithubButton = () => {
    return (
        <MediaButtonBase alt="Github Profile" href="https://github.com/scottricity">
            <img alt="Github" style={{ imageRendering: "auto" }} width={60} src="../brands/glWhite.png" /><GitHub color={"action"} sx={{ verticalAlign: "middle" }} />
        </MediaButtonBase>
    )
}

var DiscordProfile = () => {
    return (
        <Box sx={{ verticalAlign: "middle" }}>
            <Typography fontFamily={"GGSansBold"} fontSize={18} color={"#5865F2"}>scottricity <img alt="Discord" width={25} style={{ verticalAlign: "middle" }} src="../brands/DiscordMarkBlue.png" /></Typography>
        </Box>
    )
}

var RobloxProfile = () => {
    return (
        <IconButton sx={{verticalAlign: "middle", p: 0.5}} target="_blank" href="https://www.roblox.com/users/50564727/profile">
            <img alt="Roblox" style={{ imageRendering: "auto" }} width={25} src="../brands/Roblox.png" />
        </IconButton>
    )
}

export { LinkedInButton, NPMButton, GithubButton, DiscordProfile, RobloxProfile }