import { Avatar, Container, Divider, Grid, Paper, Typography } from "@mui/material"
import { LinkedInButton, GithubButton, NPMButton, DiscordProfile, RobloxProfile } from "./MediaButtons"

var ProfileCard = (props) => {
    let constrain = 250;

    function transforms(x: number, y: number, el) {
        let box = el.getBoundingClientRect();
        let calcX = -(y - box.y - (box.height / 2)) / constrain;
        let calcY = (x - box.x - (box.width / 2)) / constrain;

        return "perspective(130px) "
            + "   rotateX(" + calcX + "deg) "
            + "   rotateY(" + calcY + "deg) "
    };

    function transformElement(el, xyEl) {
        el.style.transform = transforms.apply(null, xyEl);
    }

    document.onmousemove = function (e) {

        let ex1Layer = document.getElementById("profileCardPos");
        if (ex1Layer && ex1Layer.style) {
            let xy = [e.clientX, e.clientY];
            let position = xy.concat([ex1Layer as any]);

            window.requestAnimationFrame(function () {
                transformElement(ex1Layer, position);
            });
        }
    };

    return (
        <Container sx={{ marginBottom: 8 }} maxWidth="sm">
            <div id="hoverDownUp">
                <Paper id="profileCardPos" sx={{ p: 1 }}>
                    <center>
                        <Avatar sx={{ width: "40%", height: "40%" }} src="/profile/headshot.png"></Avatar>
                        <Typography variant='h5' className='pFullName'>Jonathan Scott</Typography>
                        <Divider variant="middle" />
                        <Container sx={{ p: 1 }}>
                            <Typography variant="h5" fontWeight={300}>Your typical <a rel="noreferrer" target='_blank' href='https://www.coursera.org/ca/articles/full-stack-developer'>Full-Stack</a> developer.</Typography>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item>
                                    <LinkedInButton />
                                </Grid>
                                <Grid item>
                                    <GithubButton />
                                </Grid>
                                <Grid item>
                                    <NPMButton />
                                </Grid>
                                <Grid item>
                                    <DiscordProfile />
                                </Grid>
                                <Grid item>
                                    <RobloxProfile />
                                </Grid>
                            </Grid>
                        </Container>
                    </center>
                </Paper>
            </div>
        </Container>
    )
}

export default ProfileCard