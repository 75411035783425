import React, { useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Box, Points, PointMaterial, Sphere, OrbitControls, OrthographicCamera } from '@react-three/drei'
import { random } from "maath"
import { BufferAttribute, Color, Mesh, Vector3 } from 'three'
import { Bloom, EffectComposer, SelectiveBloom } from '@react-three/postprocessing'

import { clamp, gaussianRandom, spiral } from "../modules/threejs/spaceGen"

var Stars = (props) => {
    const starCount = props.starCount || 12000

    const ref = useRef(null)
    const [sphere] = useState(() => random.inSphere(new Float32Array(starCount), { radius: 1.5 }))

    useFrame((state, delta) => {
        ref.current.rotation.x -= delta / 10
        ref.current.rotation.z -= delta / 10
    })

    return (
        <group ref={ref}>
            <Points ref={props.sf} stride={3} frustumCulled positions={sphere} {...props}>
                <PointMaterial transparent vortexColors size={0.002} sizeAttenuation={true} />
            </Points>
        </group>
    )
}

var GalaxyBackground = (props) => {

    const bloomRef = useRef(null)
    const lightRef = useRef(null)

    return (
        <Canvas camera={{ position: [0, 0, 1] }} style={{ position: "absolute", zIndex: -1 }}>
            <ambientLight ref={lightRef} />
            <Stars sf={bloomRef} />
            <EffectComposer>
                <SelectiveBloom
                    intensity={0.3}
                    lights={[lightRef]}
                    selection={[bloomRef]}
                    luminanceThreshold={0}
                    luminanceSmoothing={1}
                    mipmapBlur
                />
            </EffectComposer>

        </Canvas>
    )
}

export default GalaxyBackground